export default {
    name: 'copiedMixin',
    data: function(){
        return {
            buttonText: "COPY_LINK"
        }
    },
    methods: {
        copySuccess() {
            this.buttonText = "COPY_SUCCESS"
            setTimeout(()=> this.buttonText = 'COPY_LINK', 3000)
        },
        copyFailed() {
            alert('error')
        }
    }
}