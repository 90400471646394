<template>
    <div class="loader">
            <div class="loader-img">
                <svg  viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M41.3875 47.4186C38.5895 47.3363 36.0659 46.5681 33.9538 44.7298C33.5972 44.4006 33.46 44.2086 33.8989 43.8245C34.475 43.3306 34.8864 42.5624 35.5448 42.3155C36.2579 42.0411 36.7791 42.9465 37.41 43.2483C38.7541 43.8793 40.1531 44.3183 41.6618 44.2909C43.3625 44.2634 44.2951 43.358 44.2951 41.657C44.3225 39.078 43.335 36.883 41.552 35.0448C39.0559 32.4932 36.5048 29.9965 33.9812 27.4998C33.5972 27.1157 33.5698 26.8962 33.9538 26.4847C35.6271 24.7562 35.6271 24.7287 37.3003 26.4298C39.6319 28.7619 41.9909 31.0665 44.2951 33.4535C46.8187 36.0325 47.7513 39.1877 47.3399 42.727C47.0107 45.6627 45.3649 47.1168 42.4298 47.3912C42.0458 47.4186 41.6892 47.4186 41.3875 47.4186Z" fill="#5FDA9A"/>
                    <path d="M41.2778 1.02365C41.9362 1.10596 42.567 1.13339 43.2254 1.24314C45.5021 1.65468 46.8462 2.97163 47.2302 5.22141C47.8063 8.65096 47.0108 11.7513 44.7889 14.4949C44.3775 14.9888 44.158 14.9888 43.7466 14.5223C43.2802 13.9736 42.5122 13.5621 42.2927 12.9585C42.0459 12.3 42.8962 11.7787 43.198 11.1477C43.8563 9.77584 44.2952 8.34916 44.2677 6.81272C44.2403 5.1391 43.3077 4.20626 41.6344 4.20626C39.0559 4.20626 36.8615 5.19398 35.0511 6.97735C32.5549 9.44662 30.0862 11.9159 27.6448 14.4126C27.0962 14.9613 26.8494 14.9065 26.3556 14.3852C24.8195 12.8213 24.8195 12.8213 26.3282 11.3123C28.7146 8.92533 31.0737 6.53836 33.4875 4.17883C35.6271 2.06623 38.2879 1.1334 41.2778 1.02365Z" fill="#5FDA9A"/>
                    <path d="M6.88011 47.4186C6.38636 47.4461 5.67316 47.3638 4.95997 47.1992C2.98497 46.7327 1.69574 45.5255 1.31171 43.5501C0.65338 40.0657 1.394 36.8831 3.67073 34.0846C4.02733 33.6182 4.32906 33.4535 4.76795 34.0023C5.20684 34.551 6.00232 34.9625 6.22177 35.5661C6.44121 36.2246 5.59088 36.7459 5.28914 37.3769C4.63081 38.7488 4.19191 40.1754 4.21934 41.7119C4.24677 43.3581 5.15198 44.2909 6.77038 44.3183C9.37628 44.3458 11.5982 43.3581 13.4634 41.5473C16.0145 39.0506 18.5107 36.499 21.0068 33.9748C21.336 33.6456 21.528 33.5633 21.9395 33.9199C23.7499 35.621 23.7499 35.621 22.0218 37.3495C19.7725 39.5993 17.5232 41.8216 15.3013 44.0988C13.052 46.3212 10.2815 47.3638 6.88011 47.4186Z" fill="#5FDA9A"/>
                    <path d="M1.06489 7.08712C1.03746 6.37378 1.11975 5.66043 1.28433 4.94709C1.72322 3.02654 2.90273 1.70959 4.8503 1.32548C8.41627 0.612138 11.6531 1.38035 14.4784 3.73988C14.8899 4.06912 14.9996 4.31604 14.5333 4.70015C13.9573 5.19401 13.5458 5.98966 12.8875 6.20915C12.2566 6.42865 11.7079 5.57813 11.077 5.27633C9.70551 4.59042 8.27912 4.17887 6.74301 4.2063C5.12461 4.23374 4.19197 5.13915 4.16454 6.78533C4.13711 9.39178 5.15204 11.6141 6.96246 13.4798C9.4312 15.9765 11.9 18.4458 14.3961 20.8876C14.8899 21.354 14.9173 21.601 14.3961 22.0948C12.7777 23.6861 12.8052 23.6861 11.1868 22.0674C8.91003 19.7902 6.66073 17.5129 4.38399 15.2632C2.16212 13.0134 1.11975 10.2697 1.06489 7.08712Z" fill="#5FDA9A"/>
                    <path d="M47.4222 24.4544C47.3673 27.1157 46.5993 29.6673 44.7066 31.8622C44.35 32.2737 44.158 32.2737 43.8014 31.8896C43.3076 31.3409 42.5396 30.9019 42.2927 30.2434C42.0458 29.585 42.8687 29.0637 43.1979 28.4601C45.118 24.811 44.35 20.4761 41.3601 17.5953C38.8364 15.1809 36.4226 12.6567 33.9264 10.1874C33.5149 9.7759 33.5698 9.55642 33.9538 9.19975C35.6271 7.49869 35.5996 7.47126 37.3003 9.17232C39.6594 11.5318 42.0458 13.8639 44.35 16.2509C46.517 18.4732 47.3948 21.2169 47.4222 24.4544Z" fill="#5FDA9A"/>
                    <path d="M24.4355 1.05118C27.0414 1.10605 29.565 1.84684 31.7046 3.6302C32.1434 4.01431 32.3629 4.28868 31.7868 4.72766C31.2382 5.16664 30.8268 5.96229 30.2233 6.18178C29.565 6.40127 29.0438 5.57818 28.4403 5.24894C24.8744 3.38327 20.7872 3.98688 17.7973 6.92257C15.2737 9.39184 12.7775 11.8885 10.3087 14.4127C9.84243 14.9065 9.59555 14.934 9.1018 14.4127C7.51083 12.7939 7.51083 12.8214 9.1018 11.2026C11.4608 8.84311 13.7924 6.48358 16.1789 4.15148C18.4282 1.98401 21.1987 1.07861 24.4355 1.05118Z" fill="#5FDA9A"/>
                    <path d="M16.2063 44.2908C16.4806 44.0164 16.7 43.7695 16.9195 43.55C17.3858 43.0836 17.7973 42.3703 18.3459 42.2605C18.7848 42.1782 19.3608 42.8092 19.9094 43.111C23.5577 45.1688 28.0014 44.4005 30.9365 41.3551C33.3504 38.831 35.874 36.4166 38.3427 33.9198C38.7542 33.5083 38.9736 33.5632 39.3302 33.9473C41.0309 35.6209 41.0584 35.5935 39.3577 37.2945C37.0809 39.5717 34.8042 41.8764 32.5 44.1536C28.3306 48.2416 21.1164 48.5434 16.8372 44.8395C16.5903 44.6749 16.4257 44.4829 16.2063 44.2908Z" fill="#5FDA9A"/>
                    <path d="M1.06476 23.9879C1.09219 21.4638 1.86025 18.8847 3.72552 16.6898C4.08212 16.2783 4.30156 16.1685 4.68559 16.635C5.17934 17.2111 5.97483 17.6227 6.19427 18.2811C6.41372 18.9122 5.59079 19.4609 5.26163 20.0645C3.39635 23.6312 4.02726 27.7193 6.96232 30.7098C9.43107 33.234 11.9272 35.7307 14.4509 38.2C14.9446 38.6938 14.9446 38.9407 14.4509 39.4071C12.8325 40.971 12.8599 40.9985 11.2415 39.3797C9.01962 37.1574 6.79774 34.935 4.57586 32.7127C2.18941 30.2983 1.09219 27.6644 1.06476 23.9879Z" fill="#5FDA9A"/>
                    <path d="M16.2611 27.0608C16.316 26.7315 16.5628 26.5943 16.7274 26.4297C18.3184 24.7835 18.3184 24.7835 19.9094 26.3748C23.8319 30.2983 27.7545 34.2491 31.7045 38.1451C32.2531 38.6664 32.1983 38.9407 31.6771 39.4346C30.0861 40.971 30.1135 40.971 28.5226 39.4071C24.6 35.4837 20.6774 31.5603 16.7549 27.6369C16.6177 27.4449 16.4257 27.2528 16.2611 27.0608Z" fill="#5FDA9A"/>
                    <path d="M24.8469 30.1337C25.0114 29.969 25.1212 29.8044 25.2583 29.6672C29.6198 25.3048 33.9812 20.9425 38.3427 16.5527C38.6719 16.2234 38.8364 16.2234 39.193 16.5252C41.0309 18.2263 41.0583 18.2263 39.2479 20.0096C35.3802 23.8782 31.485 27.7741 27.6173 31.6701C27.0962 32.1914 26.7944 32.2737 26.3281 31.6701C25.999 31.2311 25.56 30.8744 25.1486 30.4903C25.0937 30.408 24.984 30.2983 24.8469 30.1337Z" fill="#5FDA9A"/>
                    <path d="M21.5552 16.2509C21.6649 16.3607 21.8021 16.4704 21.9118 16.5802C22.2958 16.9643 22.6799 17.4033 23.0913 17.7599C23.6125 18.1989 23.6125 18.4733 23.0913 18.9671C18.7847 23.2198 14.533 27.4999 10.2813 31.7799C9.8424 32.2189 9.59552 32.2463 9.1292 31.7799C7.5108 30.1337 7.48337 30.1612 9.1292 28.4876C13.0243 24.5916 16.9195 20.6682 20.842 16.7722C21.0615 16.6076 21.2261 16.3333 21.5552 16.2509Z" fill="#5FDA9A"/>
                    <path d="M16.2612 9.63873C16.371 9.52899 16.4807 9.39181 16.5904 9.28206C16.9744 8.89795 17.4133 8.51385 17.7699 8.1023C18.2088 7.58101 18.4831 7.58101 18.9768 8.1023C23.2286 12.4098 27.5077 16.6625 31.7869 20.9151C32.2258 21.3541 32.2807 21.601 31.8143 22.0674C30.1685 23.6862 30.1959 23.7136 28.5227 22.0674C24.6275 18.1715 20.705 14.2481 16.8098 10.3521C16.6178 10.1326 16.3161 9.99541 16.2612 9.63873Z" fill="#5FDA9A"/>
                    </svg>
            </div>
        </div>
</template>

<script>
export default {
    
}
</script>