<template>

  <div>
    <ul class="cabinet-select-page" data-cy="list-page">
      <router-link
        tag="li"
        :to="{ name: 'pool' }"
        active-class="active"
        
      >
        <a>{{lang.get("POOL")}}</a>
      </router-link>
      
      <router-link
        tag="li"
        :to="{ name: 'stake' }"
        active-class="active"
      >
        <a> {{lang.get("MY_STAKE")}}</a>
      </router-link>

      <router-link
        tag="li"
        :to="{ name: 'reward' }"
        active-class="active"
      >
        <a>{{lang.get("MY_REWARDS")}}</a>
      </router-link>
    </ul>
  </div>
</template>

<script>
import MultiLang from '@/core/multilang'; 
export default {
  data: function() {
    return {
      lang: new MultiLang(this),
    }
  },
  mounted() {
  this.lang.init()
}
}
</script>