<template>
  <div>
    <loader v-if="loader" />
    <main class="cabinet">
      <div class="container">
        <div class="cabinet-header">
          <div class="h1">{{lang.get("CABINET_WELCOME_TITLE")}}</div>
          <cabinet-navigation />
        </div>

        <div class="wrapper-content">
          <div class="sidebar">
            <div class="card">
              <div class="block_sidebar">
                <div class="block">
                  <div class="block_title">
                    <div class="title">{{lang.get("SIDEBAR_TITLE")}}</div>
                  </div>
                  <div class="h4">
                    {{lang.get("SIDEBAR_SUBTITLE")}}
                  </div>
                </div>
              </div>
              <button @click="$router.push({name: 'pool'})" data-cy='button_stake-new' class="button button_submit-input">{{lang.get("SIDEBAR_TITLE")}}</button>
            </div>
          </div>
          <div v-if="!claimedStakes || !claimedStakes.length && (!stakesInfo || !stakesInfo.length)" class="card card_empty">
              <div class="img"></div>
               <div class="h4">{{lang.get("NO_WITHDRAWN_REWARD")}}</div>
          </div>
          <div v-else-if="(stakesInfo && stakesInfo.length) && (!claimedStakes || !claimedStakes.length)" class="wrapper-content" >
            <div class="card card_empty">
              <div class="img"></div>
               <div class="h4">{{lang.get("NO_WITHDRAWN_REWARD")}}</div>
            </div>

             <div class="two_parts-reward">
              <div class="card card-reff_link">
                <div>
                  <div class="h2">{{lang.get("REF_LINK")}}</div>
                  <div class="input">
                    <input disabled :value="value" type="text" />
                  </div>
                  <div class="addinfo">
                    <i class="i-group"></i>
                    <span
                      >{{lang.get("REF_NUMBER")}}
                      {{ refNumber ? refNumber : 0 }}</span
                    >
                  </div>
                  <button
                    class="button button-copy_link"
                    v-clipboard="setReferralLink"
                    v-clipboard:success="copySuccess"
                    v-clipboard:error="copyFailed"
                     data-cy='button_copy'
                  >
                    {{ lang.get(`${buttonText}`) }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="wrapper-content">
            <div v-for="(stake, idx) in claimedStakes" :key="stake.startTime">
              <div class="card card_reward">
                <div class="card_reward-top">
                  <div>
                    <div class="h2">{{lang.get("MY_STAKE")}} | POOL {{stake.poolVersion}}</div>
                    <div class="token">
                      <div class="token_img">
                        <div :class="stake.name"></div>
                      </div>
                      <div class="token_title">
                        {{
                          stake.stakeAmount ? stake.stakeAmount.toFixed(2) : 0
                        }}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="h2">{{lang.get("STATUS")}}</div>
                    <div class="status_reward success">
                      <!-- <div class="status_reward wrong"> -->
                      <div class="status_reward-img">
                        <i class="i-check-full"></i>
                        <!-- <i class="i-close"></i> -->
                      </div>
                      <div class="h1 h1-status_reward">{{lang.get("CLAIMED")}}</div>
                    </div>
                    <div class="pool-block">
                      <div class="description">
                        <div class="title-description">{{lang.get("DATE")}}</div>
                        <div class="value-description">
                          {{
                            stake.endTime
                              ? getDateOfDeposit(stake.endTime)
                              : getDateOfDeposit(stake.startTime)
                          }}
                        </div>
                      </div>
                    </div>
                    <a v-if="stake.transactionHash"
                      :href="`https://mumbai.polygonscan.com/tx/${stake.transactionHash}`"
                      target="_blank"
                      class="button button-view_transaction"
                      >{{lang.get("VIEW_TRANSACTION")}}</a
                    >
                  </div>
                </div>
                <div>
                  <ul class="list-expected_reward" data-cy="list-expected_reward-reward">
                    <li
                      v-for="(farmToken) in stake.farmTokensList"
                      :key="farmToken.address"
                    >
                      <div class="token_img">
                        <div :class="farmToken.name"></div>
                      </div>
                      <div class="title-description">{{tokenTicker(farmToken.name)}}</div>
                      <div class="value-description">
                        {{
                         findTokenReward(stake.tokenAddress, farmToken.address, stake.stakeId, stake.poolVersion)
                        }}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- <div  class="card card_reward">
              <div class="card_reward-top">
                <div>
                  <div class="h2">My stake</div>
                  <div class="token">
                    <div class="token_img "><div ></div></div>
                    <div class="token_title">20 000</div>
                  </div>
                </div>
                <div> 
                  
                  <div class="h2 ">Status</div>
                  <div class="status_reward success">
                    <div class="status_reward-img ">
                      <i class="i-check-full"></i>
                    </div>
                    <div class="h1 h1-status_reward">
                     ??? 40 - 99.9% ???
                    </div>
                  </div>
                  <div class=" pool-block">
                    <div class="description">
                      <div class="title-description">Date </div>
                      <div class="value-description">22 April 2021  |  23:56:04 </div>
                    </div>
                  </div>
                  <a href="#" target="_blank" class="button button-view_transaction">View transaction</a>
                 </div>
              </div>
              <div>
                <ul class="list-expected_reward">
                  <li > 
                    <div class="token_img">
                      <div></div>
                    </div>
                    <div class="title-description">name </div>
                    <div class="value-description">0.0000</div>
                  </li>
                  
                </ul>
              </div>
            </div> -->

            <div class="two_parts-reward">
              <div v-if="stakesInfo && stakesInfo.length" class="card card-reff_link">
                <div>
                  <div class="h2">{{lang.get("REF_LINK")}}</div>
                  <div class="input">
                    <input disabled :value="value" type="text" />
                  </div>
                  <div class="addinfo">
                    <i class="i-group"></i>
                    <span
                      >{{lang.get("REF_NUMBER")}}
                      {{ refNumber ? refNumber : 0 }}</span
                    >
                  </div>
                  <button
                    class="button button-copy_link"
                    v-clipboard="setReferralLink"
                    v-clipboard:success="copySuccess"
                    v-clipboard:error="copyFailed"
                     data-cy='button_copy'
                  >
                    {{ lang.get(`${buttonText}`) }}
                  </button>
                </div>
              </div>
              <div class="card card-table_reward">
                <div v-if="!refRewards || !refRewards.length" class="card card_empty">
                  <div class="img"></div>
                  <div class="h4">{{lang.get("NO_REF_REWARD")}}</div>
                </div>
                <div v-else>
                  <div class="h2">{{lang.get("REF_EARNING")}}</div>
                <div class="custom_table">
                  <div class="custom_table-head">
                    <div class="td td-name_token">{{lang.get("TOKEN_NAME")}}
                      <div class="empty_square"></div></div>
                    <div class="td">{{lang.get("AMOUNT")}}</div>
                  </div>
                  <div
                    
                    class="custom_table-body"
                  >
                    <div v-for="refReward in refRewards"
                    :key="refReward.time" class="tr">
                      <div class="td">
                        <div class="token">
                          <div class="token_img">
                            <div :class="refReward.refTokenName"></div>
                          </div>
                          <div class="token_title">
                            {{ tokenTicker(refReward.refTokenName) }}
                          </div>
                        </div>
                      </div>
                      <div class="td">
                        {{
                          refReward.rewardAmount
                            ? refReward.rewardAmount.toFixed(4)
                            : "0.0000"
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import copiedMixin from "@/mixins/copiedMixin";
import MultiLang from "@/core/multilang";
import CabinetNavigation from "../../../components/CabinetNavigation.vue";
import { mapState } from "vuex";
import Config from "../../../../Config.json";
import Loader from "../../../components/Loader.vue";
export default {
  mixins: [copiedMixin],
  name: 'Reward',
  components: { CabinetNavigation, Loader },
  computed: {
    ...mapState(["stakesInfo", "currentAddress", "refNumber"]),
    setReferralLink() {
      if (localStorage.getItem("address")) {
        this.value += localStorage.getItem("address");

        return this.value;
      } else {
        return "Referral link cannot be accessed. Please login to your wallet.";
      }
    },
    
  },
  data: function () {
    return {
      lang: new MultiLang(this),
      claimedStakes: [],
      refRewards: [],
      value: `${Config.REF_BASE}?ref=`,
      loader: true,
    };
  },

  // created() {
  //   this.loader = true
  // }, 
  mounted() {
    let _this = this;
    this.lang.init();
    window.scrollTo(0, 0)
    setTimeout(async function tick() {
      try {
        if(_this.stakesInfo){
          _this.claimedStakes = _this.stakesInfo.filter(
          (stake) => stake.isActive == false
        );

        _this.loader = false
        }


        if(!_this.currentAddress) {
          _this.loader = false
        }

        const {claimEvents, refReward} = JSON.parse(localStorage.getItem(`${_this.currentAddress}`));

        claimEvents.forEach(ev => {
          if(ev.poolVersion == undefined){
            return ev.poolVersion = "V1"
          }
        })

        refReward.forEach(ev => {
          if(ev.poolVersion == undefined){
            return ev.poolVersion = "V1"
          }
        })


        const inactiveStakeInfoFull = _this.claimedStakes.map(el => {
          let token = Config.stakeTokens.find(token => token.address.toLowerCase() === el.tokenAddress.toLowerCase())

          let claimEventsForStakeId = claimEvents.filter(event => event.stakeId == el.stakeId && event.stakedTokenAddress.toLowerCase() == el.tokenAddress.toLowerCase() && event.poolVersion == el.poolVersion).map(el => {
            return {
              rewardTokenAddress: el.tokenAddress,
              rewardTokenAmount: el.amount,
              endTime: el.endTime,
              transactionHash: el.transactionHash
            }
          })

          let endTime;
          let transactionHash;

          if(claimEventsForStakeId.length){
            endTime = claimEventsForStakeId[0].endTime;
            transactionHash = claimEventsForStakeId[0].transactionHash;
          }
           

          let rewardTokenData = {
            // stakeId: el.stakeId,
            // stakeToken: el.stakedTokenAddres,
            rewards: claimEventsForStakeId
          }

          return {
            ...el,
            endTime,
            transactionHash,
            stakeTokenName: token.name,
            farmTokensRewards: rewardTokenData.rewards,
          }
        });

        
        // _this.claimedStakes = inactiveStakeInfoFull.sort((a, b) => {
        //   if(a.endTime && b.endTime) {
        //     return b.endTime - a.endTime;
        //   }else if(!a.endTime && b.endTime){
        //     return b.endTime - 0
        //   }else if(a.endTime && !b.endTime){
        //     return a.endTime - 0
        //   }else if(!a.endTime && !b.endTime){
        //     return 0
        //   }
        // });


        const noRewardStakesArr = inactiveStakeInfoFull.filter(el => el.endTime == undefined);
        
        _this.claimedStakes = inactiveStakeInfoFull.filter(el => el.endTime != undefined).sort((a, b) => {
          if(a.endTime && b.endTime) {
            return b.endTime - a.endTime;
          }
        });

        _this.claimedStakes.push(...noRewardStakesArr)



        _this.refRewards = refReward.map(el => {
          let token = Config.stakeTokens.find(token => token.address.toLowerCase() === el.rewardTokenAddress.toLowerCase() && token.poolVersion == el.poolVersion)

          return {
            ...el,
            refTokenName: token.name
          }
        });
        

        setTimeout(tick, 5000);
      } catch (ex) {
        console.log(ex);

        setTimeout(tick, 300);
      }
    }, 300);

  },
  methods: {
      tokenTicker(tokenName) {
        return Config.tokenAliases[tokenName];
      },
        getDateOfDeposit(date){
            const d = new Date(Number.parseInt(`${date.toString() + "000"}`));
            const hours = d.getHours();
            const minutes = d.getMinutes();
            const day = d.getDate();
            const month = d.getMonth();
            const year = d.getFullYear();
            return `${hours < 10 ? '0'+hours : hours}:${minutes < 10 ? '0'+minutes : minutes} | ${day < 10 ? '0'+day : day}.${month+1 < 10 ? '0'+(month+1) : (month+1)}.${year} `
        },
        getTokenName(tokenAddress){
          if(Config.stakeTokens.indexOf(tokenAddress) != -1){
            debugger
            return Config.stakeTokens.find(token => token.address.toLowerCase() === tokenAddress.toLowerCase()).name
          }
          debugger
          return Config.farmTokens.find(token => token.address.toLowerCase() === tokenAddress.toLowerCase()).name
        },
        findTokenReward(stakeToken, rewardToken, stakeId, poolVersion){
          const stake = this.claimedStakes.find(stk => stk.tokenAddress.toLowerCase() === stakeToken.toLowerCase() && stk.stakeId == stakeId && stk.poolVersion == poolVersion);

          if(stake && stake.farmTokensRewards.length){
            const reward = stake.farmTokensRewards.find(rwd => rwd.rewardTokenAddress.toLowerCase() === rewardToken.toLowerCase());
            return reward && reward.rewardTokenAmount ? Number(reward.rewardTokenAmount).toFixed(4) : "0.0000"
          }
          return "0.0000"
        }
    }
};
</script>